import { useAxios } from '@/hooks/useAxios'

const request = useAxios()

// 用户列表
export const list_user = (data) => {
    return request.post({
        url: '/auth/user/list_user',
        data,
    })
}

// 用户详情
export const user_info = (data) => {
  return request.post({
      url: '/auth/user/user_info',
      data,
  })
}

// 初始化密码
export const init_password = (data) => {
  return request.post({
      url: '/auth/user/initialize_password',
      data,
  })
}

// 修改用户信息
export const update_user = (data) => {
  return request.post({
      url: '/auth/user/update_user',
      data,
  })
}

// 禁用用户
export const ban_user = (data) => {
  return request.post({
      url: '/auth/user/ban_user',
      data,
  })
}

// 解禁用户
export const unban_user = (data) => {
  return request.post({
      url: '/auth/user/unban_user',
      data,
  })
}

// 新增用户
export const add_user = (data) => {
  return request.post({
      url: '/auth/user/add_user',
      data,
  })
}

// 角色列表
export const list_character = () => {
  return request.get({
      url: '/auth/character/list_character'
  })
}

// 添加角色
export const add_character = (data) => {
  return request.post({
      url: '/auth/character/add_character',
      data,
  })
}

// 更新角色名称
export const update_character = (data) => {
  return request.post({
      url: '/auth/character/update_character',
      data,
  })
}

// 删除角色
export const delete_character = (data) => {
  return request.post({
      url: '/auth/character/delete_character',
      data,
  })
}

// 删除角色
export const get_detail = (data) => {
  return request.post({
      url: '/auth/character/get_detail',
      data,
  })
}

// 更新权限
export const update_auth = (data) => {
  return request.post({
      url: '/auth/character/update_auth',
      data,
  })
}

// 获得登录列表
export const list_login = (data) => {
  return request.post({
      url: '/auth/log/list_login',
      data,
  })
}

// 信息列表权限
export const list_message = (data) => {
  return request.post({
      url: '/auth/log/list_message',
      data,
  })
}

// 操作列表权限
export const list_operate = (data) => {
  return request.post({
      url: '/auth/log/list_operate',
      data,
  })
}

// 操作列表权限
export const list_ent = (data) => {
  return request.get({
      url: '/system_log/custom_log/list_ent',
      data,
  })
}

// 操作列表权限
export const list_declare = (data) => {
  return request.post({
      url: '/system_log/custom_log/list_declare',
      data,
  })
}

// 操作列表权限
export const list_receipt = (data) => {
  return request.post({
      url: '/system_log/custom_log/list_receipt',
      data,
  })
}

// 操作列表权限
export const list_api_log = (data) => {
  return request.post({
      url: '/system_log/custom_log/list_api_log',
      data,
  })
}

// 操作列表权限
export const declare_detail = (data) => {
  return request.post({
      url: '/system_log/custom_log/declare_detail',
      data,
  })
}

// 操作列表权限
export const receipt_detail = (data) => {
  return request.post({
      url: '/system_log/custom_log/receipt_detail',
      data,
  })
}

// 操作列表权限
export const apilog_detail = (data) => {
  return request.post({
      url: '/system_log/custom_log/apilog_detail',
      data,
  })
}

// 新增app
export const add_app = (data) => {
    return request.post({
        url: '/system_log/app/add',
        data,
    })
  }

  // 获取app列表
export const add_list = (data) => {
    return request.post({
        url: '/system_log/app/list',
        data,
    })
  }

    // 获取app列表
export const add_detail = (data) => {
    return request.post({
        url: '/system_log/app/detail',
        data,
    })
  }

      // 获取app列表
export const update_app= (data) => {
    return request.post({
        url: '/system_log/app/update',
        data,
    })
  }

        // 冻结app
export const lock_add= (data) => {
    return request.post({
        url: '/system_log/app/lock',
        data,
    })
  }

          // 冻结app
export const get_app_secret= (data) => {
    return request.post({
        url: '/system_log/app/get_secret',
        data,
    })
  }

            // 重置秘钥
export const reset_app_secret= (data) => {
    return request.post({
        url: '/system_log/app/reset_secret',
        data,
    })
  }